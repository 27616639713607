<template>
    <div>
    <span class="modal__close"
          v-if="$parent.editAccessory"
          @click="closeUpdate"
    >
       <svg-icon name="times"/>
    </span>
        <h3 class="title text_navy">
      <span class="title__img"
            @click="back"
            v-if="!$parent.editAccessory">
        <svg-icon name="arrow-left" class=""/>
      </span>
        </h3>
        <section class="item-types">
            <div class="row"
                 v-for="(item, key) in colors"
                 v-if="checkColor(colors[key])"
            >
                <div class="col-12">
                    <h4 class="titlePage_subtitle titlePage">{{key}}</h4>
                </div>
                <div class="col-md-4 col-sm-3 col-6"
                     v-for="color in item.colours"
                     :key="color.id"
                     @click="selectColor(color, item, key)"
                >
                    <div class="box">
                        <p class="box__imgWrap" v-if="color.image">
                            <img class="box__img" :src="color.image" alt="color.colour_name">
                        </p>
                        <p class="box__imgWrap" v-else-if="color.hexcode">
                            <svg-icon name="palette" class="large_icon box__img" :style="{color: color.hexcode}"/>
                        </p>
                        <p class="box__imgWrap" v-if="!color.image && !color.hexcode">
                            <svg-icon name="palette" class="large_icon box__img"/>
                        </p>
                        <p class="text_default">{{color.colour_name}}</p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';

    export default {
        name: "AccessoriesColor",

        data() {
            return {}
        },

        props: {
            colors: Object
        },

        computed: {
            ...mapGetters({
                editAccessory: 'orderPreview/editAccessory'
            }),
        },

        methods: {
            back() {
                this.$parent.selectColor = false;
                this.$parent.selectItem = true;
                this.$store.commit('accessories/windowOpen', 'openAdditionalSize');
            },

            closeUpdate() {
                this.$emit('close');
            },

            selectColor(color, item) {
                this.$parent.accessory.color = color.id;

                if (this.$parent.editAccessory) {
                    this.$emit('update', item);
                }
                else if (this.$parent.additionalAccessories) {
                    if (this.editAccessory) {
                        this.$emit('update');
                    }
                    else {
                        this.$emit('createAdditional');
                    }
                }
                else {
                    this.$emit('create', item);
                }
            },


            checkColor(elem) {
                if (elem.colours !== undefined && Object.keys(elem.colours).length > 0) {
                    return true;
                }
                else return false;
            }
        },

        beforeDestroy() {
            // this.$store.commit('colors/colors', {});
        }
    }
</script>

<style scoped lang="scss">
    @import '../../../assets/scss/utils/vars';

    .modal__close {
        color: $green;
        top: 0.7rem;
        z-index: 999;
    }

</style>
